@use "src/variables";
@use "@sbb-esta/angular/styles";

/* custom table styling */
.cd-table, .cd-table.sbb-table { // both selectors needed for sbb-table with higher specificity and for non sbb-table

  width: 100%;
  --sbb-table-cell-background-color-hover: transparent;
  border: none;
  border-collapse: collapse;

  &.cd-table-w-unset {
    width: unset;
  }

  /** do _not_ use with 'sticky'! */
  &.cd-table-transparent {
    --sbb-table-background-color: transparent;
  }

  th { text-align: left; }

  td, th, td:is(.sbb-cell, .sbb-footer-cell) {
    vertical-align: top;

    &:last-child {
      padding-right: 0;
    }

    &.cd-table-col-tiny {
      width: variables.$tableColTinyWidth;
    }

    &.cd-table-col-small {
      width: variables.$tableColSmallWidth;
    }

    &.cd-table-col-small-plus {
      width: variables.$tableColSmallPlusWidth;
    }

    &.cd-table-col-small-plus-extra {
      width: variables.$tableColSmallPlusExtraWidth;
    }

    &.cd-table-col-medium {
      width: variables.$tableColMediumWidth;
    }

    &.cd-table-col-large {
      width: variables.$tableColLargeWidth;
    }

    &.cd-table-col-extralarge {
      width: variables.$tableColExtraLargeWidth;
    }

    &.separator-right {
      border-right: 1px solid variables.$colorSeparationBorder;
    }

    &.cd-no-padding {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 0 !important;
    }
  }

  &.cd-table-compact :has(td, th) {
    padding: variables.$gutterHeightSmall variables.$innerComponentSpacing variables.$gutterHeightSmall 0 !important;
  }

  tbody {

    tr, tr.sbb-row {
      &:first-of-type.cd-table-no-border-top td {
        border-top: none;
        border-top-color: transparent;
      }

      &:last-of-type.cd-table-no-border-bottom td {
        border-bottom: none;
        border-top-color: transparent;
      }

      td, td.sbb-cell { // need 'td' for non sbb-table and td.sbb-cell to have higher specificity for sbb-table
        border-color: variables.$colorSeparationBorder;
        @include variables.transitionShortOut(border-color);
      }
    }

    // note: for sticky columns, the opacity effect is applied to the direct cild element (otherwise the hidden columns would be visible)

    // .hoverable: highlight rows on hover
    &:hover tr.hoverable td, tr.row-unselected td {
      opacity: variables.$opacityMuted-table;
      &.sbb-table-sticky {
        opacity: 1;
        > * {
          opacity: variables.$opacityMuted-table;
        }
      }
    }

    &:hover tr:hover.hoverable td,
    &:hover tr.row-selected td, &:hover tr:is(:hover, :focus, :focus-within) :is(td, th) {
      opacity: initial !important;
      &.sbb-table-sticky {

        opacity: 1;
        background-color: var(--sbb-table-background-color);
        > * {
          opacity: initial !important;
        }
      }
    }

    // See https://github.com/sbb-design-systems/sbb-angular/issues/2438
    // tr.hoverable td, tr.hoverable td.sbb-table-sticky > * {
    tr.hoverable td {
      @include variables.transitionShortOut(opacity, border-color);
    }
    tr.hoverable:hover td, tr.hoverable:hover td.sbb-table-sticky > * {
      @include variables.transitionShortIn(opacity, border-color);
    }

    // .row-selected / .row-unselected: show selected row analogous to .hoverable
    tr.row-selected td, tr.sbb-row.row-selected td, tr.clickable:hover td {
      border-color: variables.$colorSeparationBorderHover;
    }
    // previous row would override border color of hovered row otherwise:
    tr:has(+ tr.row-selected) td, tr.sbb-row:has(+ tr.row-selected) td, tr.clickable:has(+ tr:hover) td {
      border-bottom-color: variables.$colorSeparationBorderHover;
    }

    tr.clickable {
      cursor: pointer;
    }
  }
}

.table-content-subtitle {
  font-family: styles.$fontSbbLight;
  color: variables.$colorTextMuted;
}

.table-cell-valign-middle {
  td, th {
    vertical-align: middle !important;
  }
}

.table-cell-align-bottom {
  td, th {
    vertical-align: bottom !important;
  }
}


// sbbTable overites
.cd-table thead tr th {
  color: inherit;
  font-family: styles.$fontSbbBold; // for plain tables (non sbb-table)
  border-width: 0;
}
.cd-table :is(thead, tbody, tfoot) :is(tr.sbb-row, tr.sbb-header-row, tr) td {
  padding: variables.$gutterHeight variables.$innerComponentSpacing*0.5 variables.$gutterHeight  variables.$innerComponentSpacing*0.5;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-top-style: solid;
  border-left: none;
  border-right: none;
}
.cd-table tfoot :is(tr.sbb-row, tr.sbb-header-row, tr) :is(td, th) {
  border-top: 1px solid var(--sbb-header-lean-border-bottom-color); // assure footer is separated from header when tbody is empty (e.g. storage overview)
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.cd-table :is(thead, tbody, tfoot) :is(tr.sbb-row, tr.sbb-header-row, tr) th {
  padding: 0 variables.$innerComponentSpacing*0.5 variables.$gutterHeight  variables.$innerComponentSpacing*0.5;
}
.cd-table :is(thead, tbody, tfoot) :is(tr.sbb-row, tr.sbb-header-row, tr) :is(th, td):first-of-type {
  padding-left: 0;
}
:where(html.sbb-lean) .cd-table :is(tr.sbb-row, tr.sbb-header-row, tr) :is(td, th):last-of-type {
  padding-right: 0;
}

:where(html.sbb-lean) .sbb-table thead > tr:last-child :is(th, td) {
  border-bottom: unset;
}
