@use "src/variables";

// in print view, we want to show all (no scrollbar)!
@media print {
  sbb-icon-sidebar-content, .ng-scroll-viewport, #cd-layout-content {
    overflow: visible !important;
  }
  sbb-header-lean, .cd-absolute-footer {
    position: relative !important; // make sure app header is not repeated on every page (would cover content!)
  }
  body {
    // assure aside overlays are not translucent:
    print-color-adjust: exact;

    .ng-scroll-content {
      background-color: variables.$colorBg;
    }
  }

  .hide-for-print, .cdk-overlay-container {
    display: none !important;
  }

  router-outlet {
    display: none !important; // fixes empty first page in edge for some auftraege CD-52529
  }
}

// to be used for elements that need to be shown only in print mode and hidden in screen mode
@media screen {
  .hide-for-screen {
    display: none;
  }
}
