@use "src/variables";

html.sbb-dark {
  --cd-overlay-shadow-color: transparentize($colorWhite, 0.6);

  .sbb-expansion-panel .sbb-table :is(th, td) {
    background-color: var(--sbb-expansion-panel-background-color-open);
  }
  .cd-icon-grey svg path {
    color: var(--sbb-color-text);
  }
  .user-avatar, {
    background-color: var(--sbb-color-iron);
  }
  .color-section-light-bg-important {
    background-color: var(--sbb-color-iron) !important;
  }
  app-assignment-validation-notification {
    background: linear-gradient(to bottom, var(--sbb-color-midnight) calc(100% - #{variables.$sbbAccordionPaddingHorizontal}), rgba(21, 21, 21, 0));
  }
  app-fahrplan-emmission-banner {
    color: var(--sbb-color-black);
  }
  .sbb-expansion-panel app-storage-table table.sbb-table tbody:hover tr:hover td.sbb-table-sticky {
    background-color: var(--sbb-expansion-panel-background-color-open);
  }

  // Overwrites of waggon2 for better contrast
  .waggon-container {
    --waggon-theme-bg-color: var(--sbb-milk);
  }

  .waggon-sender.waggon-empty {
    --waggon-theme-color: var(--sbb-color-sky);
  }

  .waggon-none.waggon-empty {
    --waggon-theme-color: #{variables.$colorBlack10};
  }

  .waggon-none.waggon-full {
    --waggon-theme-color: #{variables.$colorBlack80};
  }

}

html.sbb-light {
  // default is transparent, which is a problem for sticky elements with this background:
  --sbb-expansion-panel-background-color-open: var(--sbb-color-background);
}
