@use "src/variables";
@use "@sbb-esta/angular/styles";

.cd-text-muted {
  color: variables.$colorTextMuted;
}

.cd-text-unmuted {
  color: var(--sbb-color-text) !important;
}

.cd-text-display {
  font-size: 20px;
}

.cd-text-normal {
  font-family: styles.$fontSbbRoman;
  font-weight: normal;
}

.cd-text-bold {
  font-family: styles.$fontSbbBold;
  font-weight: normal;
}

.cd-text-italic {
  font-style: italic;
  font-weight: normal;
}

.cd-text-thin {
  font-family: styles.$fontSbbThin;
  font-weight: normal;
}

.cd-text-strike {
  text-decoration: line-through;
}

.cd-text-underline {
  text-decoration: underline;
}

.cd-text-small {
  font-size: variables.$sizeFontSmall;
  line-height: variables.$lineHeightSmall;
}

/** use for "required" text in labels */
.cd-mandatory-label {
  margin-left: variables.$gutterHeight;
}

.cd-empty-value {
  color: variables.$colorTextMuted;
  font-family: styles.$fontSbbLight;
}

.link-inactive {
  pointer-events: none;
  cursor: default;
  color: variables.$colorTextMuted;
}

.link-selected {
  color: variables.$colorCallToAction;

  &.hover {
    color: variables.$colorCallToActionHover;
  }
}

.cd-link-text-decoration-none {
  text-decoration: none;
}

.cd-pointer:hover {
  cursor: pointer
}

.cd-text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cd-nowrap {
  white-space: nowrap;
}

.cd-wrap {
  white-space: normal;
}

.cd-ws-pre {
  white-space: pre-line;
  word-break: break-word;
}

.cd-text-align-right {
  text-align: right;
}

.cd-text-align-center {
  text-align: center;
}

.cd-header {
  font-size: variables.$sizeFontHeader;
  margin-bottom: variables.$gutterHeight;
}

.cd-label {
  /*
   * duplicate from sacl
   * .sbb-form-field-label-wrapper (formfield.scss)
   */
    margin-bottom: styles.pxToRem(3);
    padding-left: 0;
}

.cd-warning {
  color: variables.$cdColorWarning;
  font-size: variables.$sizeFontSmall;
  line-height: variables.$lineHeightSmall;
}
