@use "src/variables";

/** to use this make sure container has "display: relative" */
.cd-curtain {
  position: absolute;
  top: 0;
  z-index: 999;

  width: 100%;
  height: 100%;

  opacity: variables.$cdCurtainOpacity;
  background-color: var(--sbb-color-background);

  @include variables.transitionShortIn(opacity, background-color);
}
