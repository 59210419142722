@use "sass:color";
@use "sass:list";
@use "sass:string";
@use "@sbb-esta/angular/styles";

/**
 * Typography
 */
$sizeFontHeader: 1.5rem;
/** 'small' is used for errors and labels */
$sizeFontSmall: 13px;
$lineHeightSmall: 16px;
$optimalLineLength: 500px;

$sbb-accordion-header-font-size: 21; // duplicate from sacl
$sbb-accordion-header-content-line-height: calc(#{styles.toEm(24)} / #{$sbb-accordion-header-font-size}); // duplicate from sacl
$sizeFontDefault: 15; // duplicate from sacl

/** assures text is vertically aligned with close icon of overlay (assuming regular column paddings are applied) */
$lineHeightAlignedWithCloseButton: 32px;

/**
 * Breakpoints
 */
$screenMinWidth: styles.$sizeBreakpointDesktop - 1px; // cover tablets with 1024px width (CSS pixel)

/**
 * Vertical Spacings
 */
$gutterSbbLabel: 0.2rem;
$gutterHeight: 8px; // duplicate from sacl
$gutterHeightSmall: 4px;
$innerComponentSpacing: 24px; // duplicate from sacl
$innerComponentSpacingSmall: 12px;
$interComponentSpacing: 80px; // duplicate from sacl
$interComponentSpacingSmall: 40px; // duplicate from sacl

/**
 * Column Layout
 */
$col-gap: 32px; // duplicated in column-layout.component.ts
$col-padding-top: $interComponentSpacingSmall;
$col-padding-right: 16px; // match sbb-expansion-panel padding ($sbbAccordionPaddingHorizontal)
$col-padding-bottom: $interComponentSpacingSmall;
$col-padding-left: 16px; // match sbb-expansion-panel padding ($sbbAccordionPaddingHorizontal)
$col-padding: $col-padding-top $col-padding-right $col-padding-bottom $col-padding-left;

/**
 * Colors
 */
$colorBlack: #000000; // duplicate from sacl
$colorWhite: #FFFFFF; // duplicate from sacl
$colorGreyLight: #DCDCDC; // duplicate from sacl
$colorGreySilver: #CDCDCD; // duplicate from sacl
$colorGrey: #666666; // duplicate from sacl
$colorGreyMedium: #979797; // duplicate from sacl
$colorRed100: #EB0000; // duplicate from sacl
$colorRed125: #C60018; // duplicate from sacl
$colorRed150: #A20013; // duplicate from sacl
$colorBlack5: #F6F6F6; // duplicate from sacl
$colorBlack10: #E5E5E5; // duplicate from sacl
$colorBlack20: #D2D2D2; // duplicate from sacl
$colorBlack30: #BDBDBD; // duplicate from sacl
$colorBlack40: #A8A8A8; // duplicate from sacl
$colorBlack60: #767676; // duplicate from sacl
$colorBlack70: #686868; // duplicate from sacl
$colorBlack72: #b7b7b7; // duplicate from sacl
$colorBlack80: #5A5A5A; // duplicate from sacl
$colorBlack90: #444444; // duplicate from sacl
$colorBlack100: #1E1E1E; // duplicate from sacl
$colorBlue: #003399; // duplicate from sacl

$colorSuccess: styles.$sbbColorGreen;
$colorMilk: #F6F6F6;
$colorNight: #143A85;
$colorSky: #0079C7;
$colorMulberry: #BA5290;

/**
 * Custom colors
 */
$colorBg: $colorWhite; // duplicate from sacl
$colorCallToAction: $colorRed100; // duplicate from sacl
$colorError: $colorRed100; // duplicate from sacl
$colorCallToActionHover: $colorRed125; // duplicate from sacl
$colorContentBg: $colorWhite; // duplicate from sacl
$colorFallbackButton: $colorBlack90; // duplicate from sacl
$colorFocusOutline: $colorGrey; // duplicate from sacl
$colorHeadline: $colorBlack; // duplicate from sacl
$colorMenuBorder: $colorBlack10; // duplicate from sacl
$colorNavigation: $colorBlue; // duplicate from sacl
$colorSecondaryButton: $colorBlack20; // duplicate from sacl
$colorSeparationBorder: $colorBlack10; // duplicate from sacl
$colorText: $colorBlack; // duplicate from sacl
$colorMetanav: $colorBlack60; // duplicate from sacl

// Context Colors
$cdColorError: styles.$sbbColorError;
$cdColorError20: color.mix(styles.$sbbColorError, styles.$sbbColorWhite, 20%);
$cdColorError10: color.mix(styles.$sbbColorError, styles.$sbbColorWhite, 10%);
$cdColorWarning: styles.$sbbColorPeach;
$cdColorWarning20: color.mix(styles.$sbbColorPeach, styles.$sbbColorWhite, 20%);
$cdColorWarning10: color.mix(styles.$sbbColorPeach, styles.$sbbColorWhite, 10%);
$cdColorSuccess: styles.$sbbColorGreen;
$cdColorSuccess20: color.mix(styles.$sbbColorGreen, styles.$sbbColorWhite, 20%);
$cdColorSuccess10: color.mix(styles.$sbbColorGreen, styles.$sbbColorWhite, 10%);


$colorBgLight: $colorMilk;
$colorTextMuted: $colorBlack60;
$colorSender: $colorNight;
$colorReceiver: $colorSky;
$colorInvolvement: $colorMulberry;
$colorSeparationBorderHover: $colorBlack40;
$colorSeparationBorderActive: $colorBlack60;

/**
 * Opacity
 */
$opacityMuted: 0.5;
$opacityMuted-table: $opacityMuted - 0.2;

/**
 * Borders
 */
$borderRadius: 2px;

/**
 * Animation-Stuff
 */
@function transitionMultipleProperties( $duration, $timing, $properties... ) {
  $transitions: ();
  @each $property in $properties {
    $transitions: list.append($transitions, string.unquote('#{$property} #{$duration} #{$timing}'), 'comma');
  }
  @return $transitions;
}

$transitionDefaultDuration: 225ms;
$transitionDefaultTiming: cubic-bezier(.5, -.02, .5, 1.02);
$transitionDefaultInDuration: 225ms;
$transitionDefaultInTiming: cubic-bezier(.25, -.02, .5, 1.02);
$transitionDefaultOutDuration: 195ms;
$transitionDefaultOutTiming: cubic-bezier(.5, -.02, .75, 1.02);
@mixin transitionDefault( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionDefaultDuration,
      $transitionDefaultTiming,
    $properties...
  );
}

@mixin transitionDefaultIn( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionDefaultInDuration,
      $transitionDefaultInTiming,
    $properties...
  );
}

@mixin transitionDefaultOut( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionDefaultOutDuration,
      $transitionDefaultOutTiming,
    $properties...
  );
}

$transitionShortDuration: 195ms;
$transitionShortTiming: $transitionDefaultTiming;
$transitionShortInDuration: 195ms;
$transitionShortInTiming: $transitionDefaultInTiming;
$transitionShortOutDuration: 180ms;
$transitionShortOutTiming: $transitionDefaultOutTiming;
@mixin transitionShort( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionShortDuration,
      $transitionShortTiming,
    $properties...
  );
}

@mixin transitionShortIn( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionShortInDuration,
      $transitionShortInTiming,
    $properties...
  );
}

@mixin transitionShortOut( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionShortOutDuration,
      $transitionShortOutTiming,
    $properties...
  );
}

$transitionLongDuration: 500ms;
$transitionLongTiming: $transitionDefaultTiming;
$transitionLongInDuration: 500ms;
$transitionLongInTiming: $transitionDefaultInTiming;
$transitionLongOutDuration: 350ms;
$transitionLongOutTiming: $transitionDefaultOutTiming;
@mixin transitionLong( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionLongDuration,
      $transitionLongTiming,
    $properties...
  );
}

@mixin transitionLongIn( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionLongInDuration,
      $transitionLongInTiming,
    $properties...
  );
}

@mixin transitionLongOut( $properties... ) {
  transition: transitionMultipleProperties(
      $transitionLongOutDuration,
      $transitionLongOutTiming,
    $properties...
  );
}

/**
 * Icons
 */
$defaultIconSize: 24px;
$inlineIconSize: 12px;

/**
 * Context-Menu
 */
$contextIconMarginRight: 10px;
$contextmenuMaskWidth: calc(2 * var(--sbb-menu-trigger-padding) + var(--sbb-icon-size-default)); // total width incl. border

/**
 *  Buttons
 */
$buttonHeight: 36px; // replicated from SACL
$inputHeight: 36px;

/**
 * Component: App
 */
$navbar-height: 54px;

/**
 * Component: Notification
 */
$notificationInfoColor: $colorText;
$notificationInfoBackgroundColor: $colorBgLight;
$notificationSuccessColor: $colorWhite;
$notificationSuccessBackgroundColor: $colorSuccess;
$notificationErrorColor: $colorWhite;
$notificationErrorBackgroundColor: $colorRed100; // duplicate from sacl
$notificationWarningColor: $colorBlack;
$notificationWarningBackgroundColor: $cdColorWarning;

/**
 * Component: ShipmentProgress
 */
$defaultRightColumnWidth: 45%;

/**
 * Component: cd-table
 */
$tableWidth500: 500px; // eg. tables in storage detail
$tableColTinyWidth: 30px;
$tableColSmallWidth: 50px; // eg. one icon
$tableColSmallPlusWidth: 65px; // eg. a id number
$tableColSmallPlusExtraWidth: 80px; // eg. a id number
$tableColMediumWidth: 100px; // eg. a name
$tableColMediumPlusWidth: 140px; // eg. a big name or a short description
$tableColLargeWidth: 200px; // eg. description
$tableColExtraLargeWidth: 400px;


/**
 * @TODO misc classes -> remove or move to namespace
 */

$cdCurtainOpacity: 0.8;
$cdDetailsDescriptionMaxWidth: 70%;

$sbbAccordionPaddingHorizontal: calc(var(--sbb-expansion-panel-padding-horizontal) - var(--sbb-border-width-thin));
$sbbAccordionPaddingVertical: var(--sbb-divider-medium);

$sbbFormFieldWrapperPaddingBottom: styles.pxToRem(23); // duplicate from sacl

$input-padding-left: 7px; // see src/angular/styles/_typography.scss:819
$input-padding-top: 6px; // see src/angular/styles/_typography.scss:819

$input-border-width: 2px; // see src/angular-core/styles/includes/_form.scss:39

$actionBarHeight: 61px;
$editorRowHeight: $inputHeight + $lineHeightSmall + 1px/* context menu increases line height */ + 1px; /* minimal margin to next row */

$hierarchyBaseWidth: $innerComponentSpacing;

$formRowGapHorizontal: $innerComponentSpacingSmall;
