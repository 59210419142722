/* You can add global styles to this file, and also import other style files */
@use "sass:meta";
@use 'variables';
@use "@sbb-esta/angular/styles";
@use '@sbb-esta/angular/typography.css';
@include meta.load-css("app/styles/layout");
@include meta.load-css("app/styles/border");
@include meta.load-css("app/styles/color");
@include meta.load-css("app/styles/typography");
@include meta.load-css("app/styles/table");
@include meta.load-css("app/styles/accordion");
@include meta.load-css("app/styles/checkbox");
@include meta.load-css("app/styles/cd-dl");
@include meta.load-css("app/styles/design-system-overrides");
@include meta.load-css("app/styles/curtain");
@include meta.load-css("app/styles/deprecated");
@include meta.load-css("app/styles/subnav");
@include meta.load-css("app/styles/print");
@include meta.load-css("app/styles/light-mode");
@include meta.load-css("app/styles/dark-mode");

.sbb-header-additional-content {
  z-index: 2; // assure notifications is above user menu (when closed)
}

:root {
  --scrollbar-offset: 4;
  --scrollbar-border-radius: 5px;
}
.copy-to-clipboard + .sbb-icon {
  height: 22px;
  width: 20px;
  opacity: variables.$opacityMuted;
  padding-left: 3px;
  cursor: pointer;
}
.copy-to-clipboard-large + .sbb-icon {
  height: variables.$inputHeight;
  width: variables.$inputHeight;
  opacity: variables.$opacityMuted;
  padding-left: variables.$gutterHeight;
  padding-top: 3px;
  cursor: pointer;
}

.optimal-text-width {
  max-width: variables.$optimalLineLength;
}

.column-label {
  font-family: styles.$fontSbbBold;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

app-generic-items-select-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
