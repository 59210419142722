@use "src/variables";

/* autoprefixer grid: on */

dl.cd-dl {
  display: grid;
  grid-row-gap: variables.$gutterHeightSmall;
  grid-column-gap: variables.$innerComponentSpacingSmall;

  margin-bottom: variables.$gutterHeightSmall; // assure subsequent dl's have similar spacing

  grid-template-columns: 18rem auto; // 18rem is a good responsive default for label 'Aussergewöhnliche Sendung Nummer'

  &.label-auto {
    grid-template-columns: auto minmax(200px, 1fr);
  }

  dt, dd {
    font-weight: normal;
  }

  dd {
    margin-left: 0;
  }

}
