@use "src/variables";
@use "@sbb-esta/angular/styles";

@mixin color-factory($name, $color) {
  .color-#{$name} {
    color: $color;
  }
  .color-#{$name}-fill {
    fill: $color;
  }
  .color-#{$name}-bg {
    background-color: $color;
  }
  .color-#{$name}-border {
    border-color: $color;
  }
  .color-#{$name}-bg-important {
    background-color: $color !important;
  }
}

// States
@include color-factory('error', variables.$colorError);
@include color-factory('warning', variables.$cdColorWarning);
// make sure contrast on orange warning (=orange) background is good (in dark mode):
.color-warning-bg {
  color: variables.$colorText;
}
@include color-factory('success', variables.$cdColorSuccess);

// section highlighting
@include color-factory('section-light', variables.$colorBlack5);

// shipment information
@include color-factory('sender', variables.$colorSender);
@include color-factory('receiver', variables.$colorReceiver);
@include color-factory('involvement', variables.$colorInvolvement);
@include color-factory('none', variables.$colorBlack10);
@include color-factory('grey', variables.$colorBlack60);

@include color-factory('eta', variables.$colorError);
@include color-factory('international', variables.$colorBlack60);

// icon colors:
@mixin icon-color($name, $color) {
  .cd-icon-#{$name} {
    svg path {
      color: $color;
    }
  }
}

@include icon-color('grey', variables.$colorBlack60);
@include icon-color('error', variables.$colorError);
@include icon-color('warn', variables.$cdColorWarning);
@include icon-color('white', styles.$sbbColorWhite);
@include icon-color('success', variables.$colorSuccess);

.cd-action-icon:hover, .cd-action-icon.active {
  cursor: pointer;

  svg * {
    color: var(--sbb-color-call-to-action-hover) !important;
  }
}
