@use "src/variables";
@use "@sbb-esta/angular/styles";

/** in-product navigation */
.cd-sub-nav {
  a.cd-sub-nav-link {
    margin: 0 0 (1.5*variables.$gutterHeight);
    display: block;
  }

  .cd-sub-nav-subsection {
    .cd-sub-nav-subsection-title {
      margin-bottom: variables.$gutterHeight;
      margin-top: variables.$innerComponentSpacing;

      font-family: styles.$fontSbbLight;
      color: variables.$colorTextMuted;
    }
  }
}
